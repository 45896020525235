<template>
    <div>
		<template v-if="edit == false">
			<vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <h2>Scénario</h2>
                    <SMSnb />
                </vs-col>
            </vs-row>
			<br/>
			<vs-table v-model="selected" pagination max-items="20" :data="scenario" @selected="editScenario" noDataText="Aucun scénario">
				<!-- header table -->
				<template slot="thead">
					<vs-th sort-key="event">Evénement</vs-th>
					<vs-th sort-key="dec">Déclencheur</vs-th>
					<vs-th sort-key="dif">Diffusion</vs-th>
					<vs-th sort-key="event">Etat</vs-th>
					<!--TODO GROUPE-->
				</template>

				<!--Body table-->
				<template slot-scope="{data}">
					<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
						<vs-td :data="data[indextr].event">
							<span>{{ data[indextr].event }}</span>
						</vs-td>
						<vs-td :data="data[indextr].dec">
							<span>{{ data[indextr].dec }}</span>
						</vs-td>
						<vs-td :data="data[indextr].dif">
							<span>{{ data[indextr].dif }}</span>
						</vs-td>
						<vs-td :data="data[indextr].size">
							<vs-switch color="success" v-model="data[indextr].size"/>
						</vs-td>
					</vs-tr>
				</template>
			</vs-table>



		</template>
		<template v-else>
			<template v-if="scenario_select.dif=='SMS'">
				<span style="color:red">
					<b>Notice d'utilisation</b><br/>
					<br/>
					- Ne pas modifier le champ [PRENOM], et [HEURE] votre système les remplira automatiquement.<br/>
					- Les SMS de type CAMPAGNE ne doivent pas être envoyés avant 8H et après 20H, le dimanche et jours fériés <br/>
					- Certains opérateurs « low cost », ex : NRJ Mobile ne supportent pas l’affichage de l’émetteur, ils afficheront un numéro à la place : 38689<br/>
					- 1 SMS = 157 caractères.<br/>
					- Les SMS CAMPAGNE doivent posséder le STOP SMS 36111.<br/>
					- Emoticônes, Smileys, Hashtags (#), Arobases (@), Éperluette (&) ne sont pas pris en charge et bloqueront votre envoi.<br/>
					- Vous pouvez ajouter l'activité en insérant l'élément [ACTIVITE] (seulement pour les SMS de rappel).<br/>
				</span>
				<br/>
				<br/>
					Message:
					<vs-textarea v-model="template" style="background-color:white;"/>	
				<br/>
				<table>
					<tr>
						<td>Caractère SMS utilisés:</td>
						<td>{{ character }} / <b>{{ sms_charac*nb_sms }}</b></td>
					</tr>
					<tr>
						<td>Estimation nombre de SMS par client:</td>
						<td><b>{{ nb_sms }}</b> SMS</td>
					</tr>
				</table>
				<br/>

				<vs-divider/>
				<vs-row>
					<vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
						<vs-button color="primary" type="filled" v-on:click="retour()">Retour</vs-button>
						<vs-button color="danger" type="filled" v-on:click="cleanScenario()">Désactiver</vs-button>
						<vs-button color="success" type="filled" v-on:click="update()">Modifier</vs-button>
					</vs-col>
				</vs-row>
			</template>

			<template v-if="scenario_select.dif=='EMAIL'">
				Message:
				<ckeditor :editor="editor" v-model="template" :config="editorConfig"></ckeditor>
				<vs-divider/>
				<vs-row>
					<vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
						<vs-button color="primary" type="filled" v-on:click="retour()">Retour</vs-button>
						<vs-button color="danger" type="filled" v-on:click="cleanScenario()">Désactiver</vs-button>
						<vs-button color="success" type="filled" v-on:click="update()">Modifier</vs-button>
					</vs-col>
				</vs-row>
			</template>
		</template>
	</div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import Studio from '@/database/models/studio'

import SMSnb from '@/components/communication/sms_view.vue'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

//const TEMPLATE_BASE_SMS = "Bonjour [PRENOM], ... .STOP SMS 36111";
//const TEMPLATE_BASE_EMAIL = 'Bonjour [PRENOM],<br/><br/>Vous trouverez ci-joint le duplicata de votre [TYPE]:<br/><a href="[URL]">Duplicata [TYPE]</a><br/><br/>Merci de ne pas répondre à ce mail.<br/>Cordialement.';

export default {
	components:{
        SMSnb,
	},
    data(){
        return{
			edit    : false,
			scenario: [],
			selected: [],

			scenario_select: {},

			template:"",
			template_select:"",
			studio:undefined,

			character   : 0,
            nb_sms      : 0,
            sms_charac  : 157,


			editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
            },
        }
	},
	mounted:function()
	{
		this.rebuild()
    },
    watch:{
        $route (){
        },
		template(){
            this.updateInfo()
        }
    },
    methods:{
		retour()
		{
			this.edit = false;
		},
		editScenario( data )
		{
			this.edit = true;
            Studio.getAllMemory((list_studio)=>
            {
				this.scenario_select = data;
                //find actual studio
                this.studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));
				this.template = decodeHTML(this.studio[ data.select ]);
				this.template_select = data.select;

				if(this.template == "" || this.template==undefined)
					this.template = data.template;

				this.updateInfo();
            })
		},
		update()
		{
			if(this.template=="" || this.template==undefined )
				return;
			if(this.template_select == "")
				return;

			//Data send
			let data = {};
			data[ this.template_select ] = this.template;
			
			//Send
			Studio.update(this.$srvApi.getStudio(), data)
			.then(()=>{
				alert("Modification enregistrée");
				this.rebuild()
				this.edit = false;
			})
		},

		updateInfo()
        {
            this.character = this.template.length;
            this.nb_sms = Math.ceil(this.character/this.sms_charac);
        },


		cleanScenario()
		{
			//Data send
			let data = {};
			data[ this.template_select ] = '';

			//Send
			Studio.update(this.$srvApi.getStudio(), data)
			.then(()=>{
				alert("Désactivation enregistrée");
				this.rebuild()
				this.edit = false;
			})
		},



		rebuild()
		{
			this.scenario = []
			Studio.getAllMemory((list_studio)=>
			{
				this.studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));

				let _size = 0;
				if( this.studio["template_booking"] )
					_size = this.studio["template_booking"].length
				this.scenario.push({
					select:"template_booking",
					event:"Réservation",
					template:"Bonjour [PRENOM], nous vous rappelons que vous avez une séance demain à [HEURE].",
					dec:"Chaque jour",
					dif:"SMS",
					size: _size,
				});

				_size = 0;
				if( this.studio["template_birthday"] )
					_size = this.studio["template_birthday"].length
				this.scenario.push({
					select:"template_birthday",
					event:"Anniversaire",
					template: "Bonjour [PRENOM], nous vous souhaitons un Joyeux Anniversaire ! STOP SMS 36111",
					dec:"Chaque jour",
					dif:"SMS",
					size: _size,
				});


				_size = 0;
				if( this.studio["template_rappel"] )
					_size = this.studio["template_rappel"].length
				this.scenario.push({
					select:"template_rappel",
					event:"Rappel",
					template: "Bonjour, nous vous voyons plus depuis un moment. Prenez rdv pour faire un bilan avec notre coach. STOP SMS 36111",
					dec:"30 Jours d'inactivités",
					dif:"SMS",
					size: _size,
				});

				_size = 0;
				if( this.studio["template_invoice"] )
					_size = this.studio["template_invoice"].length
				this.scenario.push({
					select:"template_invoice",
					event:"Facture",
					template: 'Bonjour [PRENOM],<br/><br/>Vous trouverez ci-joint le duplicata de votre [TYPE]:<br/><a href="[URL]">Duplicata [TYPE]</a><br/><br/>Merci de ne pas répondre à ce mail.<br/>Cordialement.',
					dec:"Chaque facture",
					dif:"EMAIL",
					size: _size,
				});


				//autoRefresh
				autoRefresh.SetCallback(()=>
				{
					//refresh todo
				})
				autoRefresh.refresh()
			})
		}
    }
};


var decodeHTML = function (html) {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	var rep = txt.value;
	return rep;
};

</script>